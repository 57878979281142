<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
import countryList from '@/assets/all.json'
import { useHeaderMainStore } from '~/store'

const hover = ref(false)
const countries = ref()
const search = ref()
const store = useHeaderMainStore()

interface Emits {
    (e: 'update:modelValue', data: any): void
}

const emit = defineEmits<Emits>()

const selectedCountry = ref(
    store?.selectedRegion?.slug !== 'global'
        ? countryList.find(
              ct =>
                  ct.countryCode ===
                  store?.selectedRegion?.countryCode
          )
        : countryList.find(ct => ct.countryCode === 'GB')
)
emit('update:modelValue', selectedCountry.value)
onMounted(() => {
    if (!countries.value?.length) {
        countries.value = JSON.parse(JSON.stringify(countryList))
    }
})

watch(
    () => store.selectedRegion,
    newVal => {
        if (newVal?.countryCode === 'G1') {
            const country = countryList.find(
                ct => ct.countryCode === 'GB'
            )
            selectedCountry.value = country
            emit('update:modelValue', country)
        } else if (newVal) {
            const country = countryList.find(
                ct => ct.countryCode === newVal.countryCode
            )
            selectedCountry.value = country
            emit('update:modelValue', country)
        }
    },
    { deep: true, immediate: true }
)

const countryFlagInputRef = ref()
useDetectOutsideClick(countryFlagInputRef, () => {
    hover.value = false
})
const filtredCounties = ref()
watch(
    () => search.value,
    newVal => {
        if (newVal === '') {
            filtredCounties.value = null
        } else {
            filtredCounties.value = countries.value.filter(element =>
                element.name
                    .toLowerCase()
                    .includes(newVal.toLowerCase())
            )
        }
    }
)

function selectCountry(country: Object) {
    selectedCountry.value = country
    hover.value = false
    emit('update:modelValue', country)
}
</script>

<template>
    <div
        ref="countryFlagInputRef"
        class="feedback-phone__country"
        :style="{
            cursor: 'pointer'
            // rotate: hover ? '180deg' : '0deg'
        }"
        @click.stop="hover = !hover"
    >
        <div
            v-if="selectedCountry"
            class="feedback-country__img"
            style="user-select: none"
        >
            <img
                :src="selectedCountry.icon"
                alt="icon"
                style="width: 25px"
            />
        </div>

        <div class="feedback-country__arrow">
            <SvgoChevronDown
                width="12"
                :style="{
                    transform: `rotate(${hover ? '180deg' : '0deg'})`,
                    userSelect: 'none'
                }"
            />
            <!--            <img-->
            <!--                src="@/assets/icons/chevron-down.svg"-->
            <!--                alt="icon"-->
            <!--                width="12"-->
            <!--                height="7"-->
            <!--                :style="{-->
            <!--                    transform: `rotate(${hover ? '180deg' : '0deg'})`,-->
            <!--                    userSelect: 'none'-->
            <!--                }"-->
            <!--            />-->
        </div>

        <div
            v-if="selectedCountry && selectedCountry?.phoneCode"
            class="feedback-country__prefix"
        >
            {{ selectedCountry?.phoneCode }}
        </div>

        <!-- country popup -->

        <div
            :style="{ display: hover ? 'block' : 'none' }"
            class="feedback-phone__dropdown country-popup"
            @submit.prevent="hover = false"
        >
            <!-- country search -->
            <div class="country-popup__search">
                <SvgoSearch />

                <input v-model="search" type="text" @click.stop />
            </div>

            <!-- country list -->
            <div class="country-popup__list country-list">
                <!-- country-list__item -->
                <div
                    v-for="country in filtredCounties || countries"
                    :key="country.countryCode"
                    class="country-list__item"
                    @click.stop="selectCountry(country)"
                >
                    <div class="country-list__title">
                        <img
                            :src="country.icon"
                            alt="country"
                            width="14"
                            height="10"
                            class="country-list__icon"
                        />
                        <h5>{{ country.name }}</h5>
                    </div>
                    <div class="country-list__prefix">
                        {{ country.phoneCode }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped></style>
